import React, { Component, useState, useRef, useEffect, Fragment } from "react";
import cx from "classnames";
import { withTranslation } from "react-i18next";
import styles from "../styles/components/_test-result.scss";
// ENV
import env from "../env";

import MoreInfo from "./MoreInfo";

// UI
import { ChevronDown, CheckCircle, AlertCircle } from "react-feather";
import ShowMore from "react-show-more";

// Components
import RangeChart from "./RangeChart";
import LineChart from "./LineChart";

//Helpers
import {
  calculateValues,
  parseValueToFloat,
  hasNumber,
  checkResultForFastingStatements,
  getIronTest45292PositiveOrNegativeMessage,
} from "../helpers";
import { MarkdownRender } from "./Markdown/MarkdownRender";
import { useHistory } from "react-router-dom";

function TestResult(props) {
  const { t } = props;
  const [moreInfoOpen, setMoreInfoOpen] = useState(false);
  const testResultRef = useRef();
  const { code, group, value } = props;
  const history = useHistory();
  const getBorderStylesForCard = () => {
    const style = {
      borderLeftWidth: "0.75rem",
      borderLeftStyle: "solid",
      borderLeftColor: props.resultColor,
    };

    return style;
  };

  const getBackgroundColorForCard = () => {
    const style = {
      backgroundColor: props.cardColor,
    };

    return style;
  }

  const hash = history.location.state?.hash;
  useEffect(() => {
    if (hash && hash === code) {
      testResultRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [hash]);

  let referenceHigh = props.reference_value_high;
  let positiveMessage = null;
  let negativeMessage = null;
  let hideLineChart = false;

  // Finland hard-coded exceptions
  const isPuhti = env.APP_ID === "puhti";
  if (isPuhti) {
    if (code === "13133") {
      referenceHigh = 10;
    }
    if (code === "4529") {
      hideLineChart = true;
    }
    if (code === "45292") {
      hideLineChart = true;
      const { positive, negative } = getIronTest45292PositiveOrNegativeMessage({
        groupResults: group.results,
        secondIronTestValue: value,
      });
      positiveMessage = positive;
      negativeMessage = negative;
    }
  }

  const resultAvailable = !props.pending;

  return (
    <div
      id={props.code}
      className="card"
      ref={testResultRef}
      style={{
        ...getBorderStylesForCard(), 
        ...getBackgroundColorForCard()
      }}
    >
      <div
        className={cx("result-box", {
          "result-box--open": moreInfoOpen,
        })}
      >
        <div className="result-box__left">
          <div className="result-box__main">
            <h2 className="size-medium weight-600 color-purple">
              {props.description?.title}{" "}
              {props.description?.name ? `(${props.description?.name})` : ""}
            </h2>

            <div className="markdown-content">
              <MarkdownRender markdown={props.description?.summary} />
            </div>
          </div>

          {resultAvailable && !props.isBundleTest && (
            <div className="result-box__footer-desktop">
              <p
                className={cx("result-box__readmore", {
                  "result-box__readmore--open": moreInfoOpen,
                })}
                onClick={() => setMoreInfoOpen(!moreInfoOpen)}
              >
                {moreInfoOpen
                  ? t("test_result.hide_additional_info")
                  : t("test_result.show_details")}
                <ChevronDown />
              </p>
            </div>
          )}
        </div>

        {env.APP_ID === "puhti" ? (
          <>
            {" "}
            {!props.isBundleTest && (
              <ResultCard
                {...props}
                reference_value_high={referenceHigh}
                positiveMessage={positiveMessage}
                negativeMessage={negativeMessage}
              />
            )}
          </>
        ) : (
          <>
            {" "}
            {(resultAvailable || !props.isBundleTest) && (
              <ResultCard
                {...props}
                reference_value_high={referenceHigh}
                positiveMessage={positiveMessage}
                negativeMessage={negativeMessage}
              />
            )}
          </>
        )}

        {resultAvailable && (
          <div className="result-box__footer">
            <p
              className={cx("result-box__readmore", {
                "result-box__readmore--open": moreInfoOpen,
              })}
              onClick={() => setMoreInfoOpen(!moreInfoOpen)}
            >
              {moreInfoOpen
                ? t("test_result.hide_additional_info")
                : t("test_result.show_details")}
              <ChevronDown />
            </p>
          </div>
        )}
      </div>

      {moreInfoOpen && (
        <MoreInfo
          {...props}
          reference_value_high={referenceHigh}
          hideLineChart={hideLineChart}
        />
      )}
    </div>
  );
}

const ResultCard = ({
  code,
  pending,
  value,
  unit,
  currentUser,
  last_changed,
  positiveMessage,
  negativeMessage,
  reference_value_low,
  reference_value_high,
  description,
  cardColor,
  isManuallyCompleted,
  ...otherProps
}) => {
  const { t } = otherProps;

  const fastingExceptionStatements = checkResultForFastingStatements(code);

  const {
    recommended_value_max,
    recommended_value_min,
    recommended_value_enabled,
    recommended_value_description,
  } = description;

    if (pending) {
    const pendingText = isManuallyCompleted ? "" : t("result_card.waiting_for_results")
   
    return (
      <div className="result-box__right result-box__right--pending" style={{ backgroundColor: cardColor }}>
        <div className="result-box__pending">
          {pendingText}
        </div>
      </div>
    );
  } 
  else if (
    (reference_value_low ||
      reference_value_high) /*&& !fastingExceptionStatements.length > 0)*/ &&
    hasNumber(value)
  ) {
    return (
      <div className="result-box__right result-box__right--text">
        <div className="result-box__chart">
          <RangeChart
            t={t}
            value={value}
            unit={unit}
            recommendationLow={
              recommended_value_enabled &&
              recommended_value_min &&
              recommended_value_min > 0
                ? recommended_value_min
                : null
            }
            recommendationHigh={
              recommended_value_enabled &&
              recommended_value_max &&
              recommended_value_max > 0
                ? recommended_value_max
                : null
            }
            referenceLow={reference_value_low}
            referenceHigh={reference_value_high}
            recommendedValueDescription={
              recommended_value_enabled ? recommended_value_description : null
            }
          />
        </div>
        <Statements {...otherProps} />
      </div>
    );
  } else {
    return (
      <div className="result-box__right result-box__right--text">
        <div className="result-box__label">{t("result_card.result")}</div>
        {positiveMessage || negativeMessage ? (
          <PositiveOrNegativeMessage
            value={value}
            unit={unit}
            positiveMessage={positiveMessage}
            negativeMessage={negativeMessage}
          />
        ) : (
          <div
            className={`result-box__value ${
              value && value.length < 30 ? "size-medium" : ""
            }`}
          >
            {value} {unit}
          </div>
        )}
        <Statements
          fastingExceptionStatements={fastingExceptionStatements}
          {...otherProps}
        />
      </div>
    );
  }
};

function PositiveOrNegativeMessage({
  value,
  unit,
  positiveMessage,
  negativeMessage,
}) {
  if (!positiveMessage && !negativeMessage) {
    return null;
  }
  const className = positiveMessage
    ? "inline-icon-text inline-icon-text--start color-green"
    : "inline-icon-text inline-icon-text--start color-red";

  return (
    <div className={className}>
      {positiveMessage ? (
        <CheckCircle className="inline-icon-text__icon" />
      ) : (
        <AlertCircle className="inline-icon-text__icon" />
      )}
      <div className="inline-icon-text__text">
        {positiveMessage ? positiveMessage : negativeMessage} ({value} {unit})
      </div>
    </div>
  );
}

const Statements = (props) => {
  const { t } = props;
  const statements = props.fastingExceptionStatements
    ? [...props.fastingExceptionStatements, ...props.statements]
    : props.statements;

  return (
    <div className="margin-0-5 margin-top result-box__statement">
      {statements?.length > 0 && (
        <div className="result-box__label">{t("statements.statement")}</div>
      )}
      <ShowMore
        lines={2}
        more={t("statements.show_entire_statement")}
        less={t("statements.hide_statement")}
        anchorClass="color-blue-dark"
      >
        {statements.map((statement, index) => {
          if (statement === "Katso lausunto" || typeof statement !== "string") {
            return null;
          }
          statement.replace(/(?:\r\n|\r|\n)/g, "\n");
          return (
            <p key={"s" + index}>
              {statement.split("\n").map((chunk, index) => {
                return (
                  <span key={"c" + index}>
                    {chunk}
                    <br />
                  </span>
                );
              })}
            </p>
          );
        })}
      </ShowMore>
    </div>
  );
};

export default withTranslation()(TestResult);
