import React, { Component } from "react";
import { Group } from "@vx/group";
import { GlyphDot, Glyph as CustomGlyph } from "@vx/glyph";
import { LinePath, Bar, Line } from "@vx/shape";
import { withTooltip, Tooltip } from "@vx/tooltip";
import { AxisLeft, AxisBottom } from "@vx/axis";
import { scaleTime, scaleLinear } from "@vx/scale";
import { Grid } from "@vx/grid";
import { curveLinear } from "@vx/curve";
import { ParentSize } from "@vx/responsive";
import { localPoint } from "@vx/event";
import { Point } from "@vx/point";
import { LinearGradient } from "@vx/gradient";
import { extent, bisector } from "d3-array";
import { timeFormat } from "d3-time-format";
import chroma from "chroma-js";
import {
  valueHasSmallerThanSign,
  valueHasLargerThanSign,
  parseDisplayValue,
} from "../helpers";

const chartTheme = {
  colors: {
    purple: "#39235D",
    purpleLight: "#67318E",
    gray: "#626d7b",
    white: "#FFFFFF",
    green: "#43C45F",
    yellow: "#ffd44f",
  },
  fontFamily:
    "'Albert Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
};

const mapDateTicks = (data) => {
  return data.map((result) => result.date);
};
/*
const getReferenceValueTicks = (hideMinR, hideMaxR, minR, maxR, all) => {
  if (all) {
    let ticks = [0, 0];
    for (let index = all.length - 1; index >= 0; index--) {
      if (all[index].referenceHigh || all[index].referenceLow) {
        if (!all[index].referenceLow) {
          ticks = [all[index].referenceHigh];
          break;
        } else if (!all[index].referenceHigh) {
          ticks = [all[index].referenceLow];
          break;
        } else {
          ticks = [all[index].referenceLow, all[index].referenceHigh];
          break;
        }
      }
    }
    return ticks;
  } else if (hideMinR) {
    return [maxR];
  } else if (hideMaxR) {
    return [minR];
  } else {
    return [minR, maxR];
  }
};
*/
// accessors
const x = (d) => d.date;
const y = (d) => d.value;
const bisectDate = bisector((d) => d.date).left;
const formatDate = timeFormat("%d.%m.%Y");

class LineChart extends Component {
  handleTooltip = ({ event, data, x, xScale, yScale, margin }) => {
    const { showTooltip, unit } = this.props;
    const point = localPoint(event);
    const xPoint = point.x - margin.left;
    const x0 = xScale.invert(xPoint);
    const index = bisectDate(data, x0, 1);
    const d0 = data[index - 1];
    const d1 = data[index];
    let d = d0;
    if (d1 && d1.date) {
      d = x0 - x(d0) > x(d1) - x0 ? d1 : d0;
    }

    let obj = {
      tooltipData: d,
      tooltipLeft: xScale(d.date),
      tooltipTop: yScale(d.value),
      unit: unit,
    };

    showTooltip(obj);
  };

  tooltips = () => {
    const baseString = `${
      this.props.tooltipData.displayValue && this.props.tooltipData.displayValue
    } ${this.props.unit ? this.props.unit : ""}`;
    const { t } = this.props;
    const moreThanString = t("linechart.result_is_min_tooltip") + "."; //` `;
    const lessThanString = t("linechart.result_is_max_tooltip") + "."; //` Tulos on raja-arvollinen, ja enintään tässä pisteessä`;

    return (
      <div>
        <Tooltip
          top={this.props.tooltipTop - 40}
          left={this.props.tooltipLeft}
          style={{
            backgroundColor:
              valueHasSmallerThanSign(this.props.tooltipData.displayValue) ||
              valueHasLargerThanSign(this.props.tooltipData.displayValue)
                ? chartTheme.colors.white
                : chartTheme.colors.purple,
            color:
              valueHasSmallerThanSign(this.props.tooltipData.displayValue) ||
              valueHasLargerThanSign(this.props.tooltipData.displayValue)
                ? chartTheme.colors.purple
                : chartTheme.colors.white,
            fontSize: 14,
            whiteSpace: "nowrap",
          }}
        >
          {valueHasLargerThanSign(this.props.tooltipData.displayValue) ||
          valueHasSmallerThanSign(this.props.tooltipData.displayValue)
            ? valueHasLargerThanSign(this.props.tooltipData.displayValue)
              ? moreThanString
              : lessThanString
            : baseString}
        </Tooltip>
        <Tooltip
          top={this.props.tooltipTop + 32}
          left={this.props.tooltipLeft}
          style={{
            color: chartTheme.colors.purple,
            fontSize: 14,
          }}
        >
          {/*formatDate(x(this.props.tooltipData))*/}
          {valueHasLargerThanSign(this.props.tooltipData.displayValue) ||
          valueHasSmallerThanSign(this.props.tooltipData.displayValue)
            ? formatDate(x(this.props.tooltipData)) +
              " / " +
              parseDisplayValue(this.props.tooltipData.displayValue, t) +
              " " +
              this.props.unit
            : formatDate(x(this.props.tooltipData))}
        </Tooltip>
      </div>
    );
  };

  render() {
    const data = [...this.props.data].sort(function (a, b) {
      return a.date - b.date;
    });

    return (
      <ParentSize>
        {(parent) => {
          const margin = { top: 10, bottom: 40, left: 50, right: 40 };
          const xMax = parent.width - margin.left - margin.right;
          const yMax = parent.height - margin.top - margin.bottom;
          // scales
          const xDomain = extent(data, x);
          const xScale = scaleTime({
            range: [0, xMax],
            domain: xDomain,
          });
          const yScale = scaleLinear({
            range: [yMax, 0],
            domain: [this.props.min, this.props.max],
            nice: true,
          });

          const getGreenAreaHeight = (props) => {
            const {
              hideMaxR,
              minR,
              maxR,
              recommendationLow,
              recommendationHigh,
            } = props;

            if (hideMaxR) {
              return yScale(minR) - yScale(maxR) + yScale(maxR);
            } else {
              if (recommendationLow && !recommendationHigh) {
                return yScale(recommendationLow) - yScale(maxR);
              }
              if (!recommendationLow && recommendationHigh) {
                return yScale(minR) - yScale(recommendationHigh);
              }
              if (recommendationLow && recommendationHigh) {
                return yScale(recommendationLow) - yScale(recommendationHigh);
              } else {
                return yScale(props.minR) - yScale(props.maxR);
              }
            }
          };

          const getGreenAreaY = (props) => {
            const { hideMaxR, maxR, recommendationLow, recommendationHigh } =
              props;

            if (hideMaxR) {
              return 0;
            } else {
              if (recommendationLow && !recommendationHigh) {
                return yScale(maxR);
              }
              if (!recommendationLow && recommendationHigh) {
                return yScale(recommendationHigh);
              }
              if (recommendationLow && recommendationHigh) {
                return yScale(recommendationHigh);
              } else {
                return yScale(maxR);
              }
            }
          };

          return (
            <React.Fragment>
              <svg width={parent.width} height={parent.height}>
                <Grid
                  top={margin.top}
                  left={margin.left}
                  xScale={xScale}
                  yScale={yScale}
                  stroke={`${chroma(chartTheme.colors.purple).alpha(0.25)}`}
                  width={xMax}
                  height={yMax}
                  columnTickValues={mapDateTicks(data)}
                />
                <Group top={margin.top} left={margin.left}>
                  <AxisBottom
                    scale={xScale}
                    top={yMax}
                    label={""}
                    stroke={`${chroma(chartTheme.colors.purple).alpha(0.5)}`}
                    strokeWidth={2}
                    tickValues={
                      this.props.oneResult
                        ? mapDateTicks(data)
                        : [data[0].date, data[data.length - 1].date]
                    }
                    tickFormat={(value, index) => `${formatDate(value)}`}
                    tickStroke={"transparent"}
                    tickLabelProps={(value, index) => ({
                      textAnchor: index === 0 ? "start" : "end",
                      dx: index === 0 ? "0px" : "0",
                      dy: "20px",
                      fill: chartTheme.colors.purple,
                      fontSize: "12px",
                      fontWeight: 500,
                      style: {
                        fontSize: "12px",
                        lineHeight: "12px",
                        fontWeight: "500",
                      },
                      fontFamily: chartTheme.fontFamily,
                    })}
                    tickComponent={({ formattedValue, ...tickProps }) => (
                      <text {...tickProps}>{formattedValue}</text>
                    )}
                  />
                  <AxisLeft
                    scale={yScale}
                    label={""}
                    stroke={`${chroma(chartTheme.colors.purple).alpha(0.5)}`}
                    strokeWidth={2}
                    tickStroke={"transparent"}
                    tickLabelProps={(value, index) => ({
                      textAnchor: "end",
                      dx: "-0.25rem",
                      dy: "0.25rem",
                      fill: chartTheme.colors.purple,
                      fontFamily: chartTheme.fontFamily,
                      fontSize: "12px",
                      fontWeight: 500,
                      style: {
                        fontSize: "12px",
                        lineHeight: "12px",
                        fontWeight: "500",
                      },
                    })}
                  />
                  {(this.props.minR || this.props.maxR) && (
                    <>
                      {/*
                        <AxisRight
                          scale={yScale}
                          label={""}
                          left={xMax}
                          tickValues={getReferenceValueTicks(
                            this.props.hideMinR,
                            this.props.hideMaxR,
                            this.props.recommendationLow
                              ? this.props.minR
                              : this.props.minR,
                            this.props.recommendationHigh
                              ? this.props.maxR
                              : this.props.maxR,
                            this.props.allReferenceValues
                          )}
                          stroke={`${chroma(chartTheme.colors.purple).alpha(
                            0.5
                          )}`}
                          strokeWidth={2}
                          tickStroke={"transparent"}
                          tickLabelProps={(value, index) => ({
                            textAnchor: "start",
                            dy: "0.2rem",
                            fill: `${chroma(chartTheme.colors.green).darken(
                              0.5
                            )}`,
                            fontFamily: chartTheme.fontFamily,
                            fontSize: "14px",
                            fontWeight: 500,
                            style: {
                              fontSize: "14px",
                              lineHeight: "14px",
                              fontWeight: "500",
                            },
                          })}
                        />*/}
                    </>
                  )}
                  {!this.props.allReferenceValues ? (
                    <>
                      {!this.props.minR && !this.props.maxR ? null : (
                        <>
                          <Bar
                            x={0}
                            y={getGreenAreaY(this.props)}
                            width={xMax}
                            height={getGreenAreaHeight(this.props)}
                            fill={chroma(chartTheme.colors.green).alpha(0.15)}
                          />
                          {!this.props.hideMaxR ? (
                            <Line
                              from={
                                new Point({
                                  x: 0,
                                  y: this.props.recommendationHigh
                                    ? yScale(this.props.maxR) //yScale(this.props.recommendationHigh)
                                    : yScale(this.props.maxR),
                                })
                              }
                              to={
                                new Point({
                                  x: xMax, //+ 7,
                                  y: this.props.recommendationHigh
                                    ? yScale(this.props.maxR) //yScale(this.props.recommendationHigh)
                                    : yScale(this.props.maxR),
                                })
                              }
                              stroke={chroma(chartTheme.colors.green).brighten(
                                0.4,
                              )}
                            />
                          ) : null}
                          <Line
                            from={
                              new Point({
                                x: 0,
                                y: this.props.recommendationLow
                                  ? yScale(this.props.minR)
                                  : yScale(this.props.minR),
                              })
                            }
                            to={
                              new Point({
                                x: xMax, //+ 7,
                                y: this.props.recommendationLow
                                  ? yScale(this.props.minR)
                                  : yScale(this.props.minR),
                              })
                            }
                            stroke={chroma(chartTheme.colors.green)}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {this.props.allReferenceValues.map((cur, index) => {
                        let lastIndex =
                          this.props.allReferenceValues.length - 1;
                        let prev =
                          index !== 0 &&
                          this.props.allReferenceValues[index - 1];
                        let next =
                          index !== lastIndex &&
                          this.props.allReferenceValues[index + 1];

                        if (!cur.referenceHigh && !cur.referenceLow)
                          return null;

                        return (
                          <React.Fragment key={index}>
                            <Bar
                              x={
                                index === 0
                                  ? 0
                                  : (xScale(prev.date) + xScale(cur.date)) / 2
                              }
                              y={
                                this.props.recommendationHigh
                                  ? yScale(this.props.recommendationHigh)
                                  : yScale(cur.referenceHigh)
                              }
                              width={
                                index === 0
                                  ? xScale(next.date) / 2
                                  : index === lastIndex
                                    ? xMax -
                                      (xScale(prev.date) + xScale(cur.date)) / 2
                                    : (xScale(next.date) + xScale(cur.date)) /
                                        2 -
                                      (xScale(prev.date) + xScale(cur.date)) / 2
                              }
                              height={
                                this.props.recommendationHigh
                                  ? yScale(this.props.recommendationHigh)
                                  : yScale(cur.referenceLow) -
                                    yScale(cur.referenceHigh)
                              }
                              fill={chroma(chartTheme.colors.green).alpha(0.15)}
                            />
                            {cur.referenceHigh ? (
                              <Line
                                from={
                                  new Point({
                                    x:
                                      index === 0
                                        ? 0
                                        : (xScale(prev.date) +
                                            xScale(cur.date)) /
                                          2,
                                    y: yScale(cur.referenceHigh),
                                  })
                                }
                                to={
                                  new Point({
                                    x:
                                      index === lastIndex
                                        ? xMax + 7
                                        : (xScale(next.date) +
                                            xScale(cur.date)) /
                                          2,
                                    y: yScale(cur.referenceHigh),
                                  })
                                }
                                stroke={chroma(
                                  chartTheme.colors.green,
                                ).brighten(0.4)}
                              />
                            ) : null}
                            <Line
                              from={
                                new Point({
                                  x:
                                    index === 0
                                      ? 0
                                      : (xScale(prev.date) + xScale(cur.date)) /
                                        2,
                                  y: yScale(cur.referenceLow),
                                })
                              }
                              to={
                                new Point({
                                  x:
                                    index === lastIndex
                                      ? xMax + 7
                                      : (xScale(next.date) + xScale(cur.date)) /
                                        2,
                                  y: yScale(cur.referenceLow),
                                })
                              }
                              stroke={chroma(chartTheme.colors.green).brighten(
                                0.4,
                              )}
                            />
                          </React.Fragment>
                        );
                      })}
                    </>
                  )}

                  {!this.props.allReferenceValues ? (
                    <>
                      {this.props.recommendationLow && (
                        <Bar
                          x={0}
                          y={yScale(this.props.recommendationLow)}
                          width={xMax}
                          height={
                            yScale(this.props.minR) -
                            yScale(this.props.recommendationLow)
                          }
                          fill={chroma(chartTheme.colors.yellow).alpha(0.25)}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {this.props.allReferenceValues.map((cur, index) => {
                        const refLowCurrent = cur.referenceLow;

                        let lastIndex =
                          this.props.allReferenceValues.length - 1;
                        let prev =
                          index !== 0 &&
                          this.props.allReferenceValues[index - 1];
                        let next =
                          index !== lastIndex &&
                          this.props.allReferenceValues[index + 1];

                        return (
                          <Bar
                            key={index}
                            x={
                              index === 0
                                ? 0
                                : (xScale(prev.date) + xScale(cur.date)) / 2
                            }
                            y={yScale(this.props.recommendationLow)}
                            width={
                              index === 0
                                ? xScale(next.date) / 2
                                : index === lastIndex
                                  ? xMax -
                                    (xScale(prev.date) + xScale(cur.date)) / 2
                                  : (xScale(next.date) + xScale(cur.date)) / 2 -
                                    (xScale(prev.date) + xScale(cur.date)) / 2
                            }
                            height={
                              this.props.recommendationLow
                                ? yScale(refLowCurrent) -
                                  yScale(this.props.recommendationLow)
                                : 0
                            }
                            fill={chroma(chartTheme.colors.yellow).alpha(0.25)}
                          />
                        );
                      })}
                    </>
                  )}

                  {this.props.oneResult && (
                    <>
                      <LinearGradient
                        id="single-point-gradient"
                        from={chartTheme.colors.purpleLight}
                        to={chartTheme.colors.purpleLight}
                        toOpacity={0}
                        vertical={false}
                      />
                      <Bar
                        x={xScale(xDomain[0])}
                        y={yScale(data[0].value) - 2}
                        width={(xMax - xScale(xDomain[0])) / 2}
                        height={4}
                        fill="url(#single-point-gradient)"
                      />
                    </>
                  )}

                  <LinePath
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    x={x}
                    y={y}
                    stroke={chartTheme.colors.purpleLight}
                    strokeWidth={4}
                    curve={curveLinear}
                    glyph={(d, i) => {
                      const hasMinMax =
                        valueHasSmallerThanSign(d.displayValue) ||
                        valueHasLargerThanSign(d.displayValue);

                      if (/*this.props.name !== "fS-Transf" && */ hasMinMax) {
                        return (
                          <CustomGlyph
                            left={xScale(x(d))}
                            top={yScale(y(d))}
                            stroke={chartTheme.colors.purpleLight}
                          >
                            <circle
                              r={9}
                              fill={chartTheme.colors.purpleLight}
                              stroke={chartTheme.colors.purpleLight}
                            />
                            <text
                              fontSize={12}
                              style={{ color: "white" }}
                              textAnchor="middle"
                              dy="0.35em"
                            >
                              ❕
                            </text>
                          </CustomGlyph>
                        );
                      }

                      return (
                        <g key={`line-point-${i}`}>
                          <GlyphDot
                            cx={xScale(x(d))}
                            cy={yScale(y(d))}
                            r={6}
                            fill={chartTheme.colors.purpleLight}
                            stroke={chartTheme.colors.purpleLight}
                            strokeWidth={6}
                          />

                          <GlyphDot
                            cx={xScale(x(d))}
                            cy={yScale(y(d))}
                            r={4}
                            fill={chartTheme.colors.white}
                          />
                        </g>
                      );
                    }}
                  />
                  <Bar
                    x={0}
                    y={0}
                    width={xMax}
                    height={yMax}
                    fill="transparent"
                    data={data}
                    onTouchStart={(data) => (event) =>
                      this.handleTooltip({
                        event,
                        data,
                        x,
                        xScale,
                        yScale,
                        margin,
                      })
                    }
                    onTouchMove={(data) => (event) =>
                      this.handleTooltip({
                        event,
                        data,
                        x,
                        xScale,
                        yScale,
                        margin,
                      })
                    }
                    onMouseMove={(data) => (event) =>
                      this.handleTooltip({
                        event,
                        data,
                        x,
                        xScale,
                        yScale,
                        margin,
                      })
                    }
                    onMouseLeave={(data) => (event) => this.props.hideTooltip()}
                  />
                  {this.props.tooltipData &&
                    !valueHasSmallerThanSign(
                      this.props.tooltipData.displayValue,
                    ) &&
                    !valueHasLargerThanSign(
                      this.props.tooltipData.displayValue,
                    ) && (
                      <g>
                        <Line
                          from={{ x: this.props.tooltipLeft, y: 0 }}
                          to={{ x: this.props.tooltipLeft, y: yMax }}
                          stroke={chartTheme.colors.purpleLight}
                          strokeWidth={2}
                          style={{ pointerEvents: "none" }}
                          strokeDasharray="2,2"
                        />
                        <circle
                          cx={this.props.tooltipLeft}
                          cy={this.props.tooltipTop}
                          r={4}
                          fill={chartTheme.colors.purpleLight}
                          stroke="white"
                          strokeWidth={2}
                          style={{ pointerEvents: "none" }}
                        />
                      </g>
                    )}

                  {/*this.props.recommendationHigh && (
                    <Bar
                      x={0}
                      y={getRecommendationHighY(this.props)}
                      width={xMax}
                      height={
                        yScale(this.props.recommendationHigh) -
                        yScale(this.props.maxR)
                      }
                      fill={chroma(chartTheme.colors.yellow).alpha(0.25)}
                    />
                  )*/}

                  {!this.props.allReferenceValues ? (
                    <>
                      {this.props.recommendationHigh && (
                        <Bar
                          x={0}
                          y={yScale(this.props.maxR)}
                          width={xMax}
                          height={
                            yScale(this.props.recommendationHigh) -
                            yScale(this.props.maxR)
                          }
                          fill={chroma(chartTheme.colors.yellow).alpha(0.25)}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {this.props.allReferenceValues.map((cur, index) => {
                        const refHighCurrent = cur.referenceHigh;

                        let lastIndex =
                          this.props.allReferenceValues.length - 1;
                        let prev =
                          index !== 0 &&
                          this.props.allReferenceValues[index - 1];
                        let next =
                          index !== lastIndex &&
                          this.props.allReferenceValues[index + 1];

                        const getWidth = () => {
                          if (index === 0) {
                            return xScale(next.date) / 2;
                          } else if (index === lastIndex) {
                            return (
                              xMax - (xScale(prev.date) + xScale(cur.date)) / 2
                            );
                          } else {
                            return (
                              (xScale(next.date) + xScale(cur.date)) / 2 -
                              (xScale(prev.date) + xScale(cur.date)) / 2
                            );
                          }
                        };

                        const getX = () => {
                          return index === 0
                            ? 0
                            : (xScale(prev.date) + xScale(cur.date)) / 2;
                        };

                        return (
                          <Bar
                            key={index}
                            x={getX()}
                            y={yScale(refHighCurrent)}
                            width={getWidth()}
                            height={
                              this.props.recommendationHigh
                                ? yScale(this.props.recommendationHigh) -
                                  yScale(refHighCurrent)
                                : 0
                            }
                            fill={chroma(chartTheme.colors.yellow).alpha(0.25)}
                          />
                        );
                      })}
                    </>
                  )}
                </Group>
              </svg>
              {this.props.tooltipData && this.tooltips()}
            </React.Fragment>
          );
        }}
      </ParentSize>
    );
  }
}

export default withTooltip(LineChart);
