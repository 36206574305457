import React, { useState, useEffect, useRef } from "react";

import Select, { components } from "react-select";
import { ChevronRight } from "react-feather";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";

import styles from "./FilteringHeader.module.scss";

import logoSymbol from "../../assets/logo-symbol.svg";

const selectStyles = {
  control: (styles) => {
    return {
      ...styles,
      borderRadius: "0.25rem",
      boxShadow: "none",
      border: "none",
      background: "#1D0841",
      fontSize: "16px",
      padding: "2px 4px",
    };
  },
  indicatorSeparator: (styles) => ({
    ...styles,
    background: "transparent",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: "#6e9fd5",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: "#CDC8D6",
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: "10001",
    background: "#1D0841",
    color: "#FFFFFF",
  }),
  valueContainer: (styles) => ({
    ...styles,
    background: "#1D0841",
    color: "#FFFFFF",
    padding: 0,
  }),
  multiValue: (styles) => ({
    ...styles,
    background: "#241341",
    border: "1px solid #67318e",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFFFFF",
  }),
  placeholder: (styles) => ({
    ...styles,
    opacity: "1",
    color: "#CDC8D6",
    paddingLeft: 10,
    fontWeight: "bold",
    fontStretch: "94%",
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#CDC8D6",
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    color: "#CDC8D6",
    fontWeight: "bold",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? "#67318e"
      : isFocused
      ? "#39235d"
      : null,

    ":active": {
      ...styles[":active"],
      backgroundColor: "#67318e",
    },
  }),
  input: (styles) => ({
    ...styles,
    color: "#FFFFFF",
    paddingLeft: 10,
  }),
};

const FilteringHeader = ({
  allTests,
  filteredTests,
  activeSummaryFilters,
  setActiveSummaryFilters,
  setFilteredTests,
  activeTest,
}) => {
  let resultsClone = JSON.parse(JSON.stringify(allTests));
  const { t } = useTranslation();
  const [pageScrolled, setPageScrolled] = useState(false);

  const summaries = {
    below_reference: activeTest.summary?.below_reference,
    above_reference: activeTest.summary?.above_reference,
    within_reference: activeTest.summary?.above_reference,
    no_reference: activeTest.summary?.no_reference,
  };

  const selectRef = useRef(null);

  let filterOptions = [];

  if (allTests.groups && allTests.groups.length) {
    filterOptions = allTests.groups.map((group) => {
      return {
        label: group.name,
        options:
          group.results &&
          group.results.length > 0 &&
          group.results.map((result) => {
            const name = `${result.description?.title} ${
              result.description?.name ? `(${result.description?.name})` : ""
            }`;
            return {
              value: result.code,
              label: name,
              group: group.id,
            };
          }),
      };
    });
  }

  const parseSummaryFilterCodes = (tests) => {
    return tests?.map((t) => Object.keys(t)[0]);
  };

  const summaryFilterOptions = {
    label: t("filtering_header.show_results"),
    notFilterable: true, // meaning clicking other items in the filter can never hide this due to some logic
    options: [],
  };

  [
    {
      value: "below_reference",
      label: t("result_summary.below_reference"),
      filters: parseSummaryFilterCodes(activeTest.summary?.below_reference),
    },
    {
      value: "within_reference",
      label: t("result_summary.within_references"),
      filters: parseSummaryFilterCodes(activeTest.summary?.within_reference),
    },
    {
      value: "above_reference",
      label: t("result_summary.above_reference"),
      filters: parseSummaryFilterCodes(activeTest.summary?.above_reference),
    },
    {
      value: "no_reference",
      label:
        t("result_summary.outside_references").charAt(0).toUpperCase() +
        t("result_summary.outside_references").slice(1),
      filters: parseSummaryFilterCodes(activeTest.summary?.no_reference),
    },
  ].forEach((summaryOption) => {
    if (activeTest.summary && activeTest.summary[summaryOption.value].length) {
      summaryFilterOptions.options.push(summaryOption);
    }
  });

  filterOptions =
    summaryFilterOptions.length > 0
      ? [summaryFilterOptions, ...filterOptions]
      : filterOptions;

  if (allTests.ordered_tests && allTests.ordered_tests.length) {
    let orderedTests = [];
    allTests.ordered_tests.forEach((order) => {
      const name = `${order.description?.title} ${
        order.description?.name ? `(${order.description?.name})` : ""
      }`;

      orderedTests.push({
        value: order.code,
        label: name,
        group: "pending",
      });
    });

    filterOptions.push({
      label: t("filtering_header.waiting_for_results"),
      options: orderedTests,
    });
  }

  let currentValue = [];

  if (filteredTests && filteredTests.groups && filteredTests.groups.length) {
    filteredTests.groups.forEach((group) => {
      if (group.results && group.results.length > 0) {
        group.results.forEach((result) => {
          if (result.filtered) {
            const name = `${result.description?.title} ${
              result.description?.name ? `(${result.description?.name})` : ""
            }`;

            currentValue.push({
              value: result.code,
              label: name,
              group: group.id,
            });
          }
        });
      }
    });
  }

  if (
    filteredTests &&
    filteredTests.ordered_tests &&
    filteredTests.ordered_tests.length
  ) {
    filteredTests.ordered_tests.forEach((result) => {
      if (result.filtered) {
        const name = `${result.description?.title} ${
          result.description?.name ? `(${result.description?.name})` : ""
        }`;
        currentValue.push({
          value: result.code,
          label: name,
          group: "pending",
        });
      }
    });
  }

  if (activeSummaryFilters.length > 0) {
    const summaryFilterCodes = activeSummaryFilters
      .map((f) => f.filters)
      .flat();

    const filteredCurrentValue = currentValue.filter((v) =>
      summaryFilterCodes.some((code) => v.value === code),
    );

    let summaryFilteredGroups = filterOptions.filter(
      (filterOption) =>
        filterOption.options.some((option) =>
          summaryFilterCodes.includes(option.value),
        ) || filterOption.notFilterable,
    );

    //filter out results that are not in the summary codes
    summaryFilteredGroups = summaryFilteredGroups.map((group) => {
      if (group.notFilterable) {
        return group;
      }

      group.options = group.options.filter((o) =>
        summaryFilterCodes.some((code) => o.value === code),
      );
      return group;
    });

    currentValue = [...filteredCurrentValue, ...activeSummaryFilters];

    filterOptions = summaryFilteredGroups;
  }

  useEffect(() => {
    const scrollContent = window; //document.getElementById("root");
    const testResults = document.getElementById("testResults");

    const handleScroll = () => {
      const inView = testResults.getBoundingClientRect().top < 60;

      if (inView && !pageScrolled) {
        setPageScrolled(true);
      } else if (!inView && pageScrolled) {
        setPageScrolled(false);
        selectRef.current.select.blur();
      }
    };

    const handleFocus = () => {
      if (
        (document.activeElement.id === "filter-select" ||
          document.activeElement.id === "filter-back-to-top") &&
        !pageScrolled
      ) {
        setPageScrolled(true);
      }
    };

    if (scrollContent && testResults) {
      scrollContent.addEventListener("scroll", handleScroll);
    }

    document.addEventListener("focus", handleFocus, true);

    return () => {
      scrollContent.removeEventListener("scroll", handleScroll);
    };
  }, [pageScrolled]);

  const Option = (props) => {
    const { t } = useTranslation();
    const isSummaryFilter = props.data.filters;

    let disableScroll = false;
    const currentValue = props.getValue();
    if (currentValue && currentValue.length) {
      if (currentValue.find((value) => value.value === props.value)) {
        disableScroll = false;
      } else {
        disableScroll = true;
      }
    }

    const isSelected = () => {
      if (isSummaryFilter) {
        return activeSummaryFilters.some(
          (filter) => filter.value === props.data.value,
        );
      }

      return props.isSelected;
    };

    return (
      <div className={styles.selectOption}>
        <components.Option
          className={styles.multiSelectOptionWrapper}
          {...props}
        >
          <div className={styles.multiSelectOption}>
            <div
              className={
                isSelected()
                  ? "checkbox checkbox--transparent checkbox--checked checkbox--no-top"
                  : "checkbox checkbox--transparent checkbox--no-top"
              }
            >
              <div className="checkbox__inner" />
            </div>
            <span>{props.data.label}</span>
          </div>
        </components.Option>
        {!isSummaryFilter && (
          <button
            className={styles.scrollOption}
            disabled={disableScroll}
            onClick={() => {
              selectRef.current.select.blur();
              const result = document.getElementById(props.value);
              const scrollContent = window; //document.getElementById("root");

              if (scrollContent && result) {
                scrollContent.scrollTo({
                  top: result.getBoundingClientRect().top + window.scrollY - 80,
                  left: 0,
                  behavior: "smooth",
                });
              }
            }}
          >
            <span>{t("filtering_header.redirect")}</span>
            <ChevronRight />
          </button>
        )}
      </div>
    );
  };

  const handleSummaryFilterChange = (summaries) => {
    if (!summaries[0]?.filters || !summaries) {
      setActiveSummaryFilters([]);
    }

    setActiveSummaryFilters(summaries);
  };

  return (
    <div
      className={
        pageScrolled ? `${styles.root} ${styles.visible}` : styles.root
      }
    >
      <div className={styles.inner}>
        <button
          id="filter-back-to-top"
          className={styles.backToTop}
          onClick={(e) => {
            const scrollContent = window;
            //document.getElementById("root");

            if (scrollContent) {
              scrollContent.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
              e.target.blur();
            }
          }}
        >
          <SVG src={logoSymbol} />
        </button>
        <div className={styles.selectWrapper}>
          <Select
            inputId="filter-select"
            ref={selectRef}
            options={filterOptions}
            components={{ Option }}
            isSearchable
            isMulti
            hideSelectedOptions={false}
            styles={selectStyles}
            closeMenuOnSelect={false}
            noOptionsMessage={() => (
              <div>{t("filtering_header.no_search_results")}</div>
            )}
            onChange={(e) => {
              if (!e?.length && activeSummaryFilters.length) {
                setActiveSummaryFilters([]);
                return;
              }

              //summary filter handler
              if (e && e[0] && e[0]["filters"]) {
                handleSummaryFilterChange(e);
                return;
              }

              if (e && e.length) {
                e.forEach((value) => {
                  let groupToMutate;
                  if (value.group === "pending") {
                    groupToMutate = resultsClone.ordered_tests;
                  } else {
                    groupToMutate =
                      resultsClone.groups &&
                      resultsClone.groups.length > 0 &&
                      resultsClone.groups.find(
                        (group) => group.id === value.group,
                      );
                  }

                  if (groupToMutate) {
                    if (
                      !groupToMutate["filteredCodes"] &&
                      value.group !== "pending"
                    ) {
                      groupToMutate["filteredCodes"] = [];
                    }

                    let resultToMutate;
                    if (value.group === "pending") {
                      resultToMutate =
                        groupToMutate &&
                        groupToMutate.length > 0 &&
                        groupToMutate.find(
                          (result) => result.code === value.value,
                        );
                    } else {
                      resultToMutate =
                        groupToMutate.results &&
                        groupToMutate.results.length > 0 &&
                        groupToMutate.results.find(
                          (result) => result.code === value.value,
                        );
                    }
                    if (resultToMutate) {
                      resultToMutate["filtered"] = true;
                      if (value.group !== "pending") {
                        groupToMutate["filteredCodes"].push(
                          resultToMutate.code,
                        );
                      }
                    }
                  }
                });
                setFilteredTests(resultsClone);
              } else {
                setFilteredTests(null);
              }

              const categories = document.getElementById("resultCategories");
              const scrollContent = document.getElementById("root");

              if (scrollContent && categories) {
                scrollContent.scrollTo({
                  top:
                    categories.getBoundingClientRect().top +
                    scrollContent.scrollTop -
                    80,
                  left: 0,
                });
              }
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#67318e",
              },
            })}
            placeholder={t("filtering_header.filer_results_or_redirect")}
          />
        </div>
      </div>
    </div>
  );
};

export default FilteringHeader;
