import React, { Component, Fragment } from "react";

import env from "../env";
// Components
import LineChart from "./LineChart";

import { calculateValues, parseValueToFloat, hasNumber } from "../helpers";
import { MarkdownRender } from "./Markdown/MarkdownRender";
import axios from "axios";
import { AppCache } from "../cache";
import LoadingSpinner from "./LoadingSpinner";

class MoreInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      results: [],
      description: null,
    };
  }

  componentDidMount() {
    let { code } = this.props;

    this.setState({
      error: null,
      loading: true,
    });

    const cached = AppCache.get(["results", code]);
    if (cached) {
      this.setState({
        results: cached,
        loading: false,
        error: null,
      });
    } else {
      const path = `/api/results/${code}`;
      axios
        .get(path)
        .then((response) => {
          const results = response.data;
          AppCache.set(["results", code], results);
          this.setState({
            results,
            loading: false,
            error: null,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
            error: error,
          });
        });
    }
  }

  renderSources() {
    const { description, t } = this.props;
    if (description.source_urls && description.source_urls.length > 0) {
      const urls = description.source_urls;
      return (
        <div className="margin-1 margin-top">
          {t("more_info.sources")}:{" "}
          {urls.map((url, index) => {
            return (
              <React.Fragment key={index}>
                <a
                  href={url}
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("more_info.source")} {index + 1}
                </a>{" "}
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  }
  renderReadMore() {
    const { description, t } = this.props;

    return (
      <>
        <div className="margin-1 margin-top">
          {/*description.article_url && (
            <a
              href={description.article_url}
              className="button article-link-button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("render_read_more.additional_info_of_topic")}
            </a>
          )*/}
          {description?.article_links?.length > 0 &&
            description.article_links.map((a) => (
              <Fragment key={a.url}>
                {a.url && a.description && (
                  <a
                    href={a.url}
                    className="button article-link-button"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {a.description}
                  </a>
                )}
              </Fragment>
            ))}
        </div>
      </>
    );
  }

  renderForRoundResult(t) {
    return (
      <div className="result-box__more">
        <div className="result-box__more-bottom">
          <h2 className="size-medium weight-600 color-purple">
            {t("more_info.information")}
          </h2>
          <div style={{ width: "100%" }}>
            <div className="markdown-content">
              <MarkdownRender markdown={this.props.description?.description} />
            </div>
          </div>
          {this.props.description && this.renderSources()}
          {this.renderReadMore()}
        </div>
      </div>
    );
  }

  render() {
    let { pending, description, t, code } = this.props;

    if (this.props.displayRoundResults) {
      return this.renderForRoundResult(t);
    }

    const {
      recommended_value_min,
      recommended_value_max,
      recommended_value_enabled,
    } = description;

    if (pending) {
      return (
        <div className="result-box__right result-box__right--pending">
          <div className="result-box__pending">
            {t("more_info.waiting_for_results")}
          </div>
        </div>
      );
    }

    if (this.state.loading) {
      return (
        <div className="loading-spinner" style={{ padding: "1rem" }}>
          <div className="loading-spinner__text" style={{ margin: "0" }}>
            {t("loading_spinner.loading")}
          </div>
        </div>
      );
    }

    const results = this.state.results;
    /*
    const results = [
      {
        last_changed: "2022-03-09T13:42:21.6733333",
        value: "4.0",
        reference_value_low: "3.5",
        reference_value_high: "4.4",
      },
      {
        last_changed: "2022-04-01T13:42:21.6733333",
        value: "3.9",
        reference_value_low: "3.5",
        reference_value_high: "4.6",
      },
    ];
    */

    const resultsWithNumericValues = results.filter((result) =>
      hasNumber(result.value)
    );

    if (resultsWithNumericValues.length === 0) {
      return null;
    }

    const maxValue = resultsWithNumericValues.reduce((acc, currentResult) => {
      const { value, displayValue } = parseValueToFloat(currentResult.value);
      if (value > parseValueToFloat(acc).value) {
        acc = displayValue;
      }
      return acc;
    }, 0);

    const minValue = resultsWithNumericValues.reduce((acc, currentResult) => {
      const { value, displayValue } = parseValueToFloat(currentResult.value);
      if (value < parseValueToFloat(acc).value) {
        acc = displayValue;
      }
      return acc;
    }, maxValue);

    let allReferenceValues;

    if (resultsWithNumericValues.length > 1) {
      allReferenceValues = resultsWithNumericValues.map((result) => {
        return {
          referenceLow: result.reference_value_low
            ? parseFloat(result.reference_value_low)
            : null,
          referenceHigh: result.reference_value_high
            ? parseFloat(result.reference_value_high)
            : null,

          date: new Date(result.last_changed),
        };
      });

      if (allReferenceValues.length !== results.length) {
        allReferenceValues = undefined;
      } else if (
        allReferenceValues.every(
          (val) =>
            val.referenceLow === allReferenceValues[0].referenceLow &&
            val.referenceHigh === allReferenceValues[0].referenceHigh
        )
      ) {
        allReferenceValues = undefined;
      } else if (allReferenceValues && allReferenceValues.length) {
        allReferenceValues.sort((a, b) => a.date - b.date);
      }
    }

    let calculateLow = resultsWithNumericValues[0].reference_value_low;
    let calculateHigh = resultsWithNumericValues[0].reference_value_high;

    if (allReferenceValues) {
      resultsWithNumericValues.forEach((result) => {
        if (calculateLow || parseFloat(result.reference_value_low)) {
          if (!calculateLow && result.reference_value_low) {
            calculateLow = result.reference_value_low;
          } else if (
            calculateLow &&
            result.reference_value_low &&
            parseFloat(result.reference_value_low) < parseFloat(calculateLow)
          ) {
            calculateLow = result.reference_value_low;
          }
        }
        if (calculateHigh || parseFloat(result.reference_value_high)) {
          if (!calculateHigh && result.reference_value_high) {
            calculateHigh = result.reference_value_high;
          } else if (
            calculateHigh &&
            result.reference_value_high &&
            parseFloat(result.reference_value_high) > parseFloat(calculateHigh)
          ) {
            calculateHigh = result.reference_value_high;
          }
        }
      });
    }

    const isPuhti = env.APP_ID === "puhti";
    if (isPuhti) {
      if (code === "13133") {
        calculateHigh = 10;
      }
    }

    const {
      referenceLow,
      referenceHigh,
      lowestValue,
      highestValue,
      hideLow,
      hideUpper,
    } = calculateValues(maxValue, calculateLow, calculateHigh, minValue);

    const data = resultsWithNumericValues.map((result) => ({
      date: new Date(result.last_changed),
      value: parseValueToFloat(result.value).value,
      displayValue: parseValueToFloat(result.value).displayValue,
    }));

    return (
      <div className="result-box__more">
        {data.length > 0 && !this.props.hideLineChart && (
          <div className="result-box__more-top">
            <h2 className="size-medium weight-600 color-purple">
              {t("more_info.change")}
            </h2>
            <div className="linechart-container">
              <div className="linechart-container__inner">
                <LineChart
                  t={this.props.t}
                  data={data}
                  unit={resultsWithNumericValues[0].unit}
                  min={lowestValue}
                  max={highestValue}
                  minR={referenceLow}
                  maxR={referenceHigh}
                  hideMinR={hideLow}
                  hideMaxR={hideUpper}
                  allReferenceValues={allReferenceValues}
                  recommendationLow={
                    recommended_value_enabled &&
                    recommended_value_min &&
                    recommended_value_min > 0
                      ? recommended_value_min
                      : null
                  }
                  recommendationHigh={
                    recommended_value_enabled &&
                    recommended_value_max &&
                    recommended_value_max > 0
                      ? recommended_value_max
                      : null
                  }
                  oneResult={results.length === 1}
                />
              </div>
            </div>
          </div>
        )}
        <div className="result-box__more-bottom">
          <h2 className="size-medium weight-600 color-purple">
            {t("more_info.information")}
          </h2>
          <div style={{ width: "100%" }}>
            <div className="markdown-content">
              <MarkdownRender markdown={this.props.description?.description} />
            </div>
          </div>
          {this.props.description && this.renderSources()}
          {this.renderReadMore()}
        </div>
      </div>
    );
  }
}

export default MoreInfo;
